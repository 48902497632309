<template>
  <div>
    <BaseInput ref="baseInput" v-model="value" :field="field" />
  </div>
</template>

<script>
import BaseInput from "@/components/Tools/FormHelper/Components/BaseInput";
import { input } from "@/components/Tools/FormHelper/Helper/mixins";

export default {
  components: { BaseInput },
  mixins: [input],
  methods: {
    validate() {
      return this.$refs.baseInput.validate();
    }
  }
};
</script>
