<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper ref="fieldWrapper" :field="field" :input-group="true">
      <!------------ START: Form field input ------------>
      <ContentEditable
        ref="input"
        v-model="computedValue"
        :placeholder="getSnippet(field.placeholder)"
        :input-class="validationClass"
        :highlight="highlightType"
        :is-disabled="isDisabled"
        @change="onChange"
        @focus="showVariables = true"
        @esc="showVariables = !showVariables"
      />
      <!------------ END: Form field input ------------>
      <!------------ START: Return type ------------>
      <div class="input-group-append">
        <span
          v-b-tooltip.noninteractive.html.top="returnTypeText"
          class="input-group-text border-left-0 bg-white px-2"
          :class="{ 'cursor-pointer': emptyOrNull }"
          @click="toggleEmptyType"
        >
          <i :class="[returnTypeIcon]" />
          <i v-if="emptyOrNull" class="fal fa-rotate toggle-empty" />
        </span>
      </div>
      <!------------ END: Return type ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
    <!------------ START: Error messages tooltip ------------>
    <ErrorsTooltip
      v-if="isMounted && inputEl"
      :target="inputEl"
      :errors="validationErrors"
    />
    <!------------ END: Error messages tooltip ------------>
    <!------------ START: Variables dropdown ------------>
    <VariablesDropdown
      v-if="enableVariables && isMounted"
      v-model="showVariables"
      :filter="filter"
      :el="el"
      :input-el="inputEl"
      @select="setVariable"
    />
    <!------------ END: Variables dropdown ------------>
  </div>
</template>

<script>
import {
  getReturnType,
  typeCast,
  stringify
} from "@/components/Tools/FormHelper/Helper/functions";
import { returnTypeIcons } from "@/components/Tools/FormHelper/Helper/constants";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import VariablesDropdown from "@/components/Tools/FormHelper/Components/VariablesDropdown/VariablesDropdown";
import ErrorsTooltip from "@/components/Tools/FormHelper/Components/ErrorsTooltip";
import ContentEditable from "@/components/Tools/FormHelper/Components/ContentEditable";

export default {
  name: "BaseInput",
  components: {
    ContentEditable,
    ErrorsTooltip,
    VariablesDropdown,
    FieldWrapper
  },
  mixins: [base],
  data() {
    return {
      returnType: "",
      showVariables: false,
      isMounted: false
    };
  },
  computed: {
    computedValue: {
      get: function () {
        let value = this.$v.value.$model;
        if (
          (!this.$v.value.$dirty || this.field.type === "number") &&
          value === null
        ) {
          value = "";
        }
        // Always get value as string to be editable
        return this.castInput ? stringify(value) : String(value);
      },
      set: function (value) {
        if (this.field.type === "number" && value === "") {
          value = "null";
        }
        // Set value cast or just as string
        this.$v.value.$model = this.castInput ? typeCast(value) : String(value);
      }
    },
    // Get input group element
    el: function () {
      return this.$refs.fieldWrapper?.$refs.inputGroup;
    },
    // Get input element
    inputEl: function () {
      return this.$refs.input.$el;
    },
    // Get return type icon
    returnTypeIcon: function () {
      return returnTypeIcons[this.returnType];
    },
    // Get return type text based on current return type
    returnTypeText: function () {
      if (!this.returnType) {
        return "";
      }
      let prefix = this.$t("formHelper.returnType"),
        typeText = this.$t("formHelper.returnTypes." + this.returnType);
      return `${prefix}: <span class="font-italic">${typeText}</span>`;
    },
    // Get filter prop for variables dropdown as string
    filter: function () {
      let value = this.$v.value.$model;
      if (!this.$v.value.$dirty && value === null) {
        value = "";
      }
      return String(value);
    },
    emptyOrNull: function () {
      return (
        this.field.type === "text" && (this.value === "" || this.value === null)
      );
    },
    highlightType: function () {
      // If field type is number, highlight default
      // Else highlight as set with castInput flag
      return this.field.type === "number"
        ? "default"
        : this.castInput
        ? "all"
        : "default";
    },
    castInput: function () {
      return this.field.type === "number" ? true : this.field.castInput;
    }
  },
  mounted() {
    this.emptyOrNull ? this.setEmptyReturnType() : this.setReturnType();
    this.isMounted = true;
  },
  methods: {
    onChange() {
      // Set return type
      this.setReturnType();
      // Emit change to update v-model
      this.$emit("input", this.value);
    },
    setVariable(variable) {
      this.$v.value.$model = variable;
      this.setReturnType();
      this.$emit("input", this.$v.value.$model);
      this.showVariables = false;
    },
    toggleEmptyType() {
      // Abort if not allowed or value not empty
      if (!this.emptyOrNull) {
        return;
      }
      if (this.$v.value.$model === "") {
        // If value is empty string, set null
        this.$v.value.$model = null;
      } else if (this.$v.value.$model === null) {
        // If value is null, set empty string
        this.$v.value.$model = "";
      }
      this.$v.value.$reset();
      this.setEmptyReturnType();
      // Emit change to update v-model
      this.$emit("input", this.$v.value.$model);
    },
    setReturnType() {
      // Set return type
      this.returnType = getReturnType(
        this.$v.value.$model,
        this.field.type,
        this.field.enableVariables ?? this.options.enableVariables ?? false,
        this.castInput
      );
    },
    setEmptyReturnType() {
      // Set return type
      this.returnType = getReturnType(
        this.$v.value.$model,
        this.field.type,
        this.field.enableVariables ?? this.options.enableVariables ?? false,
        true
      );
    }
  }
};
</script>

<style lang="scss">
.contenteditable-content {
  max-width: 100%;
  height: 100%;
  overflow-x: auto;
  white-space: nowrap;
  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }
  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;
  scrollbar-width: none;
  &:before {
    content: "";
  }
}
</style>
