<template>
  <div
    ref="input"
    class="form-control"
    :contenteditable="!isDisabled"
    :data-placeholder="placeholder"
    :class="[inputClass, !value ? 'empty' : '']"
    v-bind="{ disabled: isDisabled }"
    @input="onInput"
    @focus="onFocus"
    @keyup="onKeyUp"
  />
</template>

<script>
import {
  highlight,
  restoreCursorPosition,
  saveCursorPosition
} from "@/components/Tools/FormHelper/Helper/functions";

export default {
  components: {},
  model: {
    prop: "defaultValue"
  },
  props: {
    defaultValue: {
      type: null,
      default: ""
    },
    inputClass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    enableVariables: {
      type: Boolean,
      default: true
    },
    highlight: {
      type: String,
      default: "default"
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Set default value from v-model as value
      value: this.defaultValue,
      lastCursorPos: null
    };
  },
  computed: {},
  watch: {
    defaultValue: function () {
      if (this.defaultValue !== this.value) {
        this.value = this.defaultValue;
        // Set value
        this.$refs.input.innerHTML = highlight(
          this.value,
          this.enableVariables,
          this.highlight
        );
        restoreCursorPosition(this.$refs.input, this.lastCursorPos);
      }
    }
  },
  mounted() {
    // Set initial value
    this.$refs.input.innerHTML = highlight(
      this.value,
      this.enableVariables,
      this.highlight
    );
  },
  methods: {
    // Get text as snippet by given prefix
    getSnippet(text) {
      return this.$te(`${this.options.snippetPrefix}.${text}`)
        ? this.$t(`${this.options.snippetPrefix}.${text}`)
        : text;
    },
    onInput() {
      // Save current cursor position
      this.lastCursorPos = saveCursorPosition(this.$refs.input);
      // Get value from contenteditable
      let value = this.$refs.input.innerText.replace(/(\r\n|\n|\r)/gm, "");
      this.value = value;
      // Set new html value
      this.$refs.input.innerHTML = highlight(
        value,
        this.enableVariables,
        this.highlight
      );
      // Emit change to update v-model
      this.$emit("input", value);
      this.$emit("change");
      // Restore the cursor position
      restoreCursorPosition(this.$refs.input, this.lastCursorPos);
    },
    onFocus() {
      // Emit focus event
      this.$emit("focus");
    },
    onKeyUp(e) {
      let key = e.key;
      switch (key) {
        case "Escape":
          key = "esc";
          break;
        case "Enter":
          key = "enter";
          break;
        default:
          break;
      }
      this.$emit(key);
    }
  }
};
</script>

<style lang="scss" scoped>
// Make yedi scss variables accessible
@import "~@/assets/sass/components/_variables.yedi.scss";
// Fake a placeholder
[contenteditable] {
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  &:not(:focus).empty:before {
    content: attr(data-placeholder);
    color: $text-muted;
    cursor: text;
    pointer-events: none;
  }
}
</style>
